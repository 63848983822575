export const TASKS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const USERS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const DETAILS_TAB = {
    INVOICE_LIST_COMPLETE: {
        pageSize: 20,
        rowHeight: 70,
    },
};

export const PAGE_NAMES_FOR_VIEW = {
    TASKS_PAGE: 'ExternalTasksPage',
    DASHBOARD_PAGE: 'DashboardPage',
};

export const CUSTOM_FIELD_TYPES = {
    INVOICE: 'Invoice',
    CUSTOMER: 'Customer',
    CREDIT: 'Credit',
};

export const OPEN_SUPPORT_TICKETS = {
    STATUS: 1,
};
