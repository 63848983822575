/**
 * File responsible for mapping the redux state.
 */

import { combineReducers, Dispatch, Action, AnyAction } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, RouterState } from 'connected-react-router';
// import { History } from 'history';

// Types
import { AuthState } from './auth/types';
import { StoredRouteState } from './storedRoute/types';
import { RolesState } from './roles/types';
import { UsersState } from './users/types';
import { InvitesState } from './invites/types';
import { CompaniesState } from './companies/types';
import { AppCommonState } from './common/types';
import { TasksState } from './tasks/types';

// Sagas
import authSaga from './auth/sagas';
import rolesSaga from './roles/sagas';
import usersSaga from './users/sagas';
import invitesSaga from './invites/sagas';
import companiesSaga from './companies/sagas';
import commonSaga from './common/sagas';
import tasksSaga from './tasks/sagas';

// Reducers
import { authReducer } from './auth/reducer';
import { storedRouteReducer } from './storedRoute/reducer';
import { rolesReducer } from './roles/reducer';
import { usersReducer } from './users/reducer';
import { invitesReducer } from './invites/reducer';
import { companiesReducer } from './companies/reducer';
import { commonReducer } from './common/reducer';
import { tasksReducer } from './tasks/reducer';

// The top-level state object
export interface ApplicationState {
    app: AppCommonState;
    auth: AuthState;
    storedRoute: StoredRouteState;
    roles: RolesState;
    users: UsersState;
    invites: InvitesState;
    companies: CompaniesState;
    tasks: TasksState;

    router: RouterState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = (history: History) =>
    combineReducers({
        app: commonReducer,
        auth: authReducer,
        storedRoute: storedRouteReducer,
        roles: rolesReducer,
        users: usersReducer,
        invites: invitesReducer,
        companies: companiesReducer,
        tasks: tasksReducer,

        router: connectRouter(history),
    });

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
    // yield all([fork(heroesSaga), fork(teamsSaga)]);
    yield all([
        fork(authSaga),
        fork(rolesSaga),
        fork(usersSaga),
        fork(invitesSaga),
        fork(companiesSaga),
        fork(commonSaga),
        fork(tasksSaga),
    ]);
}
