import { DynamicObject } from '../../utils/commonInterfaces';
export enum CompaniesActionTypes {
    GET_USER_COMPANIES_REQUEST = '@@companies/GET_USER_COMPANIES_REQUEST',
    GET_USER_COMPANIES_SUCCESS = '@@companies/GET_USER_COMPANIES_SUCCESS',
    SELECT_USER_COMPANY_REQUEST = '@@companies/SELECT_USER_COMPANY_REQUEST',
    SELECT_USER_COMPANY_SUCCESS = '@@companies/SELECT_USER_COMPANY_SUCCESS',
    SAVE_USER_COMPANY_REQUEST = '@@companies/SAVE_USER_COMPANY_REQUEST',
    SAVE_USER_COMPANY_RESPONSE = '@@companies/SAVE_USER_COMPANY_RESPONSE',
    UPLOAD_COMPANY_LOGO_REQUEST = '@@companies/UPLOAD_COMPANY_LOGO_REQUEST',
    GET_SYSTEM_CONFIGURATION_SUCCESS = '@@companies/GET_SYSTEM_CONFIGURATION_SUCCESS',
    SAVE_COMPANY_ATB_SETTINGS_REQUEST = '@@companies/SAVE_COMPANY_ATB_SETTINGS_REQUEST',
    RESET_ALL_COMPANY_DATA_TABLE_FILTERS = '@@companies/RESET_ALL_COMPANY_DATA_TABLE_FILTERS',
}

export interface CompaniesState {
    readonly loading: boolean;
    readonly userCompanies: Company[];
    readonly selectedUserCompany: Company | undefined;
    readonly saveLoading: boolean;
    readonly systemConfiguration: SystemConfiguration | undefined;
}

export interface SystemConfiguration {
    readonly Environment: string;
    readonly LiveMode: boolean;
}

export interface Company {
    readonly CompanyId: string;
    readonly Name: string;
    readonly Email: string;
    readonly LogoPath: string;
    readonly CreatedDateTime: string;
    readonly UpdateDateTime: string;
    readonly AddressLine1: string;
    readonly AddressLine2: string;
    readonly Suburb: string;
    readonly State: string;
    readonly Postcode: string;
    readonly PhoneNumber: string;
    readonly ABN: string;
    readonly Timezone: string;
    readonly Industry: string;
    readonly PrimaryColorTheme: string;
    readonly SecondaryColorTheme: string;
    readonly CompanyContacts: CompanyContact[];
    readonly CustomFieldsConfigure: CompanyCustomFieldConfigure[];
    readonly IsDueDateView: boolean;
    readonly UsingCustomerWorkflow: boolean;
    readonly LockedDeliveryMethod: boolean;
    readonly Region: string;
    readonly LanguagePackage: LanguagePackage;
    readonly PaymentOptions: DynamicObject;
    readonly ImportType: string;
    readonly CloudImportType: string;
    readonly ApiImportType: string;
}

export interface CompanyCustomFieldConfigure {
    Type: string;
    Number: number;
    FieldName: string;
    DisplayInNotification: boolean;
}

export interface CompanyContact {
    readonly Name: string;
    readonly PhoneNumber: string;
    readonly Email: string;
    readonly Type: string;
    readonly ReceiveNotification: boolean;
}

export interface LanguagePackage {
    Language: string;
    Suburb: string;
    State: string;
    Postcode: string;
    CompanyRegistrationNumber: string;
    BankBSB: string;
    BankAccountNumber: string;
    DateFormat: string;
    CurrentDateFormat: string;
}
