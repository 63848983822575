/**
 * Component to populate each row items for the Active tasks table.
 */

import { Checkbox, Col, Row } from 'antd';
import {
    capitalize,
    get,
    includes,
    isEqual,
    isUndefined,
    lowerCase,
} from 'lodash';
import React, { memo } from 'react';
import {
    taskHistoryAutomation,
    taskHistoryBatchTypes,
    taskItemColorsAndLabel,
} from '../../constants/tasksSortAndFilters';
import { Task } from '../../store/tasks/types';
import {
    checkIfTaskReady,
    getAssetsPath,
    getCompanyName,
    getIndefiniteArticleBySucceedingWordOrPhrase,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAutomationIconsHandler } from '../common/AutomationIconsHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import ImageWithLoading from '../common/ImageWithLoading';

const assetsPath = getAssetsPath();
const imgPlaceholder = `${assetsPath}/imagePlaceholder.png`;

interface IProps {
    readonly item: Task;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject) => void;
    readonly onCheckboxClick?: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateUTCToLocal?: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly generateAutomationIcon?: (
        type: string,
        automation: string | null,
        iconColor: string,
        iconCircleColor?: string | null,
        fontSizeProp?: number | string | null
    ) => JSX.Element | undefined;
    readonly usingCustomerWorkflow?: boolean;
    readonly lockedDeliveryMethod?: boolean;
    readonly selectedId?: string;
}
const TaskItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    selectedId,
    formatCurrency,
    formatDateUTCToLocal,
    generateAutomationIcon,
    usingCustomerWorkflow,
}: IProps) => {
    const {
        Id,
        ActionDate,
        Type,
        Automation,
        Workflow,
        State,
        Customer,
        // Invoice,
        Ticket,
        Company,
    } = item;
    const StateName = get(Workflow, 'StateName', '');
    // const InvoiceNumber = get(Invoice, 'Number', '');
    // const AmountOwing = get(Invoice, 'AmountOwing');
    const CustomerCode = get(Customer, 'CustomerCode');

    const ActionDateLocal = formatDateUTCToLocal
        ? formatDateUTCToLocal(ActionDate)
        : ActionDate;

    const isTaskReady = checkIfTaskReady(State, ActionDate);

    /**
     * Function called when checkbox is clicked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo: any = item;
        itemInfo.key = Id;
        itemInfo.isReady = isTaskReady;
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row item is clicked.
     */
    const handleRowClick = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;
        if (onRowClick) onRowClick(itemInfo);
    };

    const { itemColor, readyLabel, iconCircleColor, iconContentColor } =
        taskItemColorsAndLabel(Type, StateName, isTaskReady, ActionDate);

    /**
     * Function that converts a number into currency format if HOC function `formatCurrency` is available.
     * @param amount - number to format
     */
    // const handleFormatCurrency = (amount: number) => {
    //     return formatCurrency ? formatCurrency(amount) : null;
    // };

    /**
     * Function that calls an HOC function to generate the automation icon appropriate for this item.
     */
    const generateItemIcon = () => {
        if (generateAutomationIcon) {
            return generateAutomationIcon(
                Type,
                Automation,
                iconContentColor,
                iconCircleColor
            );
        }
    };

    const companyNameCorrect = getCompanyName(Customer);

    /**
     * Function that gets the ticket option reason if present.
     */
    const populateTicketOptionTypeLabel = () => {
        return get(Ticket, 'TicketOption.Reason', '');
    };

    /**
     * Function to get the title and description needed based on task type and other properties.
     */
    const populateTaskTitleAndDescription = () => {
        const indefiniteArticle =
            getIndefiniteArticleBySucceedingWordOrPhrase(StateName);
        let title, description;
        if (
            Type === taskHistoryBatchTypes.Communication &&
            Automation === taskHistoryAutomation.Automatic
        ) {
            if (usingCustomerWorkflow) {
                title = `${capitalize(
                    StateName
                )} for customer ${companyNameCorrect}`;
                description = (
                    <div>
                        Send {indefiniteArticle} {lowerCase(StateName)} to
                        customer
                    </div>
                );
            } else {
                title = `${capitalize(StateName)} for invoice`;
                description = (
                    <div>
                        Send {indefiniteArticle} {lowerCase(StateName)} for
                        invoice
                    </div>
                );
            }
        } else if (Type === taskHistoryBatchTypes.Ticket) {
            title = `${StateName} - customer assistance ticket #${get(
                Ticket,
                'TicketNumber',
                ''
            )}`;
            description = <div>{populateTicketOptionTypeLabel()}</div>;
        } else if (Type === taskHistoryBatchTypes.Collection) {
            if (usingCustomerWorkflow) {
                title = `Escalate customer to collections`;
                description = (
                    <div>Notify the collections team about the customer.</div>
                );
            } else {
                title = `Escalate invoice to collections`;
                description = (
                    <div>Notify the collections team about the invoice.</div>
                );
            }
        }

        return { title, description };
    };

    const logoPath = get(Company, 'LogoPath');
    const companyName = get(Company, 'Name');

    const { title, description } = populateTaskTitleAndDescription();

    const customerInfo = (
        <Row
            type="flex"
            gutter={{ xxl: 40, xl: 20, lg: 10, md: 10, sm: 10, xs: 10 }}
        >
            {!isUndefined(CustomerCode) && <Col>{CustomerCode}</Col>}
            <Col>{companyNameCorrect}</Col>
        </Row>
    );

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    padding: 10,
                }}
            >
                <Row
                    className="fx-center-xl center-flex-i h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                >
                    <Col xxl={4} xl={5} lg={5} md={5} sm={5} xs={5}>
                        <Row type="flex" align="middle" gutter={20}>
                            {onCheckboxClick && (
                                <Col>
                                    <div
                                        className="ta-center"
                                        style={{
                                            paddingTop: 5,
                                        }}
                                    >
                                        <Checkbox
                                            checked={includes(
                                                selectedRowKeys,
                                                Id
                                            )}
                                            onClick={handleCheckboxClick}
                                        />
                                    </div>
                                </Col>
                            )}

                            <Col className="ta-center">
                                <Row>
                                    <Col>
                                        <div className="task-company-logo-cont">
                                            {includes(logoPath, 'https://') ||
                                            includes(logoPath, 'http://') ? (
                                                <ImageWithLoading
                                                    src={
                                                        logoPath ||
                                                        imgPlaceholder
                                                    }
                                                    className="task-company-logo"
                                                    hasPlaceholder
                                                    fromList
                                                />
                                            ) : (
                                                <img
                                                    className="task-company-logo"
                                                    alt=""
                                                    src={imgPlaceholder}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>{companyName}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={15} xl={14} lg={14} md={14} sm={14} xs={14}>
                        <Row type="flex" align="middle" gutter={30}>
                            <Col className="fx-01 ta-center">
                                <div className="automation-icon-cont">
                                    {generateItemIcon()}
                                </div>
                            </Col>
                            <Col className="fx-09">
                                <Row>
                                    <Col>
                                        <b className="ws-nw va-top">{title}</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>{description}</Col>
                                </Row>
                                <Row gutter={30} type="flex">
                                    <Col>{customerInfo}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={5} className="ta-right">
                        <Row>
                            <Col>
                                <b className={itemColor}>
                                    {readyLabel} {ActionDateLocal}
                                </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {/* {isTaskReady && (
                                    <b className={itemColor}>
                                        Action by {ActionDateLocal}
                                    </b>
                                )} */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>&nbsp;</Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style) &&
        prevProps.onCheckboxClick === nextProps.onCheckboxClick
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withAutomationIconsHandler(withDateFormatHandler(TaskItemComponent)),
    arePropsEqual
);
