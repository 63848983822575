import update from 'immutability-helper';
import { concat, get, isEqual, uniqWith } from 'lodash';
import { Reducer } from 'redux';
import { TASKS_PAGE } from '../../config/tableAndPageConstants';
import {
    tasksSortByOptions,
    tasksStatusFilterOptions,
} from '../../constants/tasksSortAndFilters';
import { TasksActionTypes, TasksState } from './types';

// Type-safe initialState!
export const initialState: TasksState = {
    loading: false,
    saveTaskDataLoading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: TASKS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {},
    taskStatus: get(tasksStatusFilterOptions, `${2}.value`),
    tableFilter: undefined,
    sortBy: tasksSortByOptions[1].value,
    actionFilterOptions: {},
    actionFilterOptionsLoading: false,
    sortAscending: true,
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
    },
    notifyCustomersLoading: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<TasksState> = (state = initialState, action) => {
    switch (action.type) {
        case TasksActionTypes.GET_TASKS_REQUEST: {
            return { ...state, loading: true };
        }
        case TasksActionTypes.GET_TASKS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.data, {
                    $set: action.payload.data,
                });
            } else {
                // update(state.data, {
                //     $push: action.payload.data,
                // });
                newDataState = uniqWith(
                    concat(state.data, action.payload.data),
                    isEqual
                );
            }
            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case TasksActionTypes.GET_TASKS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        case TasksActionTypes.UPDATE_TASKS_FILTERS: {
            return { ...state, filters: action.payload };
        }
        case TasksActionTypes.UPDATE_TASKS_SORT_BY_AND_STATE: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                taskStatus: action.payload.taskStatus,
                sortAscending: action.payload.sortAscending,
            };
        }
        case TasksActionTypes.UPDATE_TASKS_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }
        case TasksActionTypes.CLEAR_TASKS_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                tableFilter: initialState.tableFilter,
            };
        }
        case TasksActionTypes.CLEAR_TASKS_STATE_DATA: {
            return {
                ...initialState,
            };
        }

        //Single Record
        case TasksActionTypes.SET_TASK_SELECTED_ID_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.SET_TASK_SELECTED_ID_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.GET_TASK_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: true,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.GET_TASK_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages: initialState.activeData.errorMessages,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.GET_TASK_DATA_ERROR: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return newState;
        }

        case TasksActionTypes.NOTIFY_CUSTOMERS_REQUEST: {
            return {
                ...state,
                notifyCustomersLoading: true,
            };
        }

        case TasksActionTypes.NOTIFY_CUSTOMERS_RESPONSE: {
            return {
                ...state,
                notifyCustomersLoading: false,
            };
        }

        case TasksActionTypes.CLEAR_TASK_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as tasksReducer };
