import { action } from 'typesafe-actions';
import {
    TasksActionTypes,
    Task,
    GetTasksRequestPayload,
    NotifyCustomersRequestPayload,
} from './types';
import { PageData } from '../common/types';

export const getTasksRequestAction = (payload: GetTasksRequestPayload) =>
    action(TasksActionTypes.GET_TASKS_REQUEST, payload);
export const getTasksSuccessAction = (payload: {
    data: Task[];
    pageData: PageData;
}) => action(TasksActionTypes.GET_TASKS_SUCCESS, payload);
export const getTasksErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASKS_ERROR, payload);

export const updateTasksFiltersAction = (
    filters: GetTasksRequestPayload['filters']
) => action(TasksActionTypes.UPDATE_TASKS_FILTERS, filters);
export const updateTasksSortByAndStateAction = (tableSortAndState: {
    sortBy: GetTasksRequestPayload['sortBy'];
    sortAscending: boolean;
    taskStatus: string;
}) =>
    action(TasksActionTypes.UPDATE_TASKS_SORT_BY_AND_STATE, tableSortAndState);

export const updateTasksTableFilterAction = (tableFilter: string) =>
    action(TasksActionTypes.UPDATE_TASKS_TABLE_FILTER_STATE, tableFilter);

/**
 * Action for resetting table filters and sort values.
 */
export const clearTasksStateAllTableFiltersAction = () =>
    action(TasksActionTypes.CLEAR_TASKS_STATE_ALL_TABLE_FILTERS);

export const clearTasksStateDataAction = () =>
    action(TasksActionTypes.CLEAR_TASKS_STATE_DATA);

// Single Record
export const setSelectedTaskIdRequestAction = (
    taskId: string | null,
    callback: () => void
) =>
    action(TasksActionTypes.SET_TASK_SELECTED_ID_REQUEST, {
        taskId,
        callback,
    });
export const setSelectedTaskIdSuccessAction = (taskId: string | null) =>
    action(TasksActionTypes.SET_TASK_SELECTED_ID_SUCCESS, taskId);

/**
 * Actions for getting tasks data.
 */
export const getTaskDataRequestAction = (taskId: string) =>
    action(TasksActionTypes.GET_TASK_DATA_REQUEST, {
        taskId,
    });
export const getTaskDataSuccessAction = (payload: { record: {} }) =>
    action(TasksActionTypes.GET_TASK_DATA_SUCCESS, payload);
export const getTaskDataErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_DATA_ERROR, payload);

export const clearTaskDataAction = () =>
    action(TasksActionTypes.CLEAR_TASK_DATA_SUCCESS);

/**
 * Action for getting the tasks ready state of selected items.
 * @param payload
 */
export const checkTasksReadyStateAction = (payload: GetTasksRequestPayload) =>
    action(TasksActionTypes.CHECK_TASKS_READY_STATE, payload);

/**
 * Actions for notifying customers (communication automation).
 * @param payload
 */
export const notifyCustomersRequestAction = (
    payload: NotifyCustomersRequestPayload
) => action(TasksActionTypes.NOTIFY_CUSTOMERS_REQUEST, payload);
export const notifyCustomersResponseAction = () =>
    action(TasksActionTypes.NOTIFY_CUSTOMERS_RESPONSE);

/**
 * Function for getting the Options for the `Action` options on resolving ticket panel.
 * @param workflowId
 * @param stateName
 * @param callback
 */
export const getResolveTicketOptionsRequestAction = (
    workflowId: string,
    stateName: string,
    callback?: (response: any) => void
) =>
    action(TasksActionTypes.GET_RESOLVE_TICKET_OPTIONS_REQUEST, {
        workflowId,
        stateName,
        callback,
    });

/**
 * Action called when submitting the form when resolving a ticket task.
 * @param payload
 */
export const resolveQueryRequestAction = (payload: {}) =>
    action(TasksActionTypes.RESOLVE_QUERY_REQUEST, payload);

/**
 * Action called when clicking `Yes` upon confirmation of escalating to collections of the selected items.
 * @param payload
 */
export const escalateToCollectionsAction = (payload: {}) =>
    action(TasksActionTypes.ESCALATE_TO_COLLECTIONS, payload);
