import { tasksSortByOptions } from '../../constants/tasksSortAndFilters';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { Company } from '../companies/types';

export enum TasksActionTypes {
    GET_TASKS_REQUEST = '@@tasks/GET_TASKS_REQUEST',
    GET_TASKS_SUCCESS = '@@tasks/GET_TASKS_SUCCESS',
    GET_TASKS_ERROR = '@@tasks/GET_TASKS_ERROR',
    UPDATE_TASKS_FILTERS = '@@tasks/UPDATE_TASKS_FILTERS',
    UPDATE_TASKS_SORT_BY_AND_STATE = '@@tasks/UPDATE_TASKS_SORT_BY_AND_STATE',
    UPDATE_TASKS_TABLE_FILTER_STATE = '@@tasks/UPDATE_TASKS_TABLE_FILTER_STATE',
    CLEAR_TASKS_STATE_ALL_TABLE_FILTERS = '@@tasks/CLEAR_TASKS_STATE_ALL_TABLE_FILTERS',
    CLEAR_TASKS_STATE_DATA = '@@tasks/CLEAR_TASKS_STATE_DATA',

    SET_TASK_SELECTED_ID_REQUEST = '@@tasks/SET_TASK_SELECTED_ID_REQUEST',
    SET_TASK_SELECTED_ID_SUCCESS = '@@tasks/SET_TASK_SELECTED_ID_SUCCESS',
    GET_TASK_DATA_REQUEST = '@@tasks/GET_TASK_DATA_REQUEST',
    GET_TASK_DATA_SUCCESS = '@@tasks/GET_TASK_DATA_SUCCESS',
    GET_TASK_DATA_ERROR = '@@tasks/GET_TASK_DATA_ERROR',
    CLEAR_TASK_DATA_SUCCESS = '@@tasks/CLEAR_TASK_DATA_SUCCESS',

    CHECK_TASKS_READY_STATE = '@@tasks/CHECK_TASKS_READY_STATE',
    NOTIFY_CUSTOMERS_REQUEST = '@@tasks/NOTIFY_CUSTOMERS_REQUEST',
    NOTIFY_CUSTOMERS_RESPONSE = '@@tasks/NOTIFY_CUSTOMERS_RESPONSE',
    GET_RESOLVE_TICKET_OPTIONS_REQUEST = '@@tasks/GET_RESOLVE_TICKET_OPTIONS_REQUEST',
    RESOLVE_QUERY_REQUEST = '@@tasks/RESOLVE_QUERY_REQUEST',
    ESCALATE_TO_COLLECTIONS = '@@tasks/ESCALATE_TO_COLLECTIONS',
}

export interface TasksState {
    readonly loading: boolean;
    readonly saveTaskDataLoading: boolean;
    readonly errorMessages: string[];
    readonly data: Task[];
    readonly pageData: PageData;
    readonly filters: GetTasksRequestPayload['filters'];
    readonly sortBy: GetTasksRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly taskStatus: string;
    readonly tableFilter: string | undefined;
    readonly actionFilterOptions: DynamicObject;
    readonly actionFilterOptionsLoading: boolean;
    readonly activeData: {
        loading: boolean;
        record: {};
        selectedId: string | null;
        errorMessages: string[];
    };
    readonly notifyCustomersLoading: boolean;
}

interface Workflow {
    readonly WorkflowId: string;
    readonly StateName: string;
}

export interface Task {
    readonly Id: string;
    readonly CompanyId: string;
    readonly Type: string;
    readonly Automation: string;
    readonly ActionDate: string;
    readonly State: string;
    readonly Workflow: Workflow;
    readonly Customer: Customer;
    readonly Invoice: Invoice;
    readonly ClosedDateTime: string;
    readonly Ticket: Ticket;
    readonly AssignedUser: AssignedUser;
    readonly AssignedEmailAddress: string;
    readonly Company: Company;
}

export interface AssignedUser {
    readonly GivenName: string;
    readonly FamilyName: string;
}

export interface TicketOption {
    readonly Id: string;
    readonly Reason: string;
    readonly CaptureMessage: string;
    readonly Type: string;
}

export interface Ticket {
    readonly Id: string;
    readonly TicketNumber: string;
    readonly State: string;
    readonly Details: string;
    readonly ResolvedReason: string;
    readonly ResolvedDateTime: string;
    readonly CreatedDateTime: string;
    readonly Customer: Customer;
    readonly TicketOption: TicketOption;
    readonly Uri: string;
}

export interface Customer {
    readonly Id: string;
    readonly CustomerCode: string;
    readonly CompanyName: string;
    readonly IsVip: boolean;
    readonly DisplayName: string;
    readonly Contacts: Contact[];
}

export interface Invoice {
    readonly Id: string;
    readonly CompanyId: string;
    readonly InvoiceCode: string;
    readonly Number: string;
    readonly CreatedDate: string;
    readonly LocalCreatedDate: string;
    readonly DueDate: string;
    readonly LocalDueDate: string;
    readonly OriginalAmount: number;
    readonly AmountOwing: number;
    readonly State: string;
    readonly SettledDate: string;
    readonly LocalSettledDate: string;
    readonly Type: string;
    readonly CreatedDateTime: string;
    readonly UpdateDateTime: string;
}

export interface GetTasksRequestPayload {
    callback?: (response: any) => void;
    filters: {};
    sortBy: typeof tasksSortByOptions[number]['value'];
    taskIds?: string[];
    excludeTasks?: boolean;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    taskTableFilter?: string;
    lockedDeliveryMethod?: boolean;
    isUsingCloudImportType?: boolean;
}

export interface NotifyCustomersRequestPayload {
    filter: {};
    taskIds: string[] | number[];
    excludeTasks: boolean;
    callback?: (response: ResponseModalObject) => void;
}

export interface Contact {
    readonly Id: string;
    readonly AddressLine1: string;
    readonly AddressLine2: string;
    readonly City: string;
    readonly Country: string;
    readonly Email: string;
    readonly FirstName: string;
    readonly LastName: string;
    readonly MobileNumber: string;
    readonly Postcode: string;
    readonly State: string;
}

export interface WorkflowTransition {
    Trigger: string;
    DataCapture: DataCapture | null;
}

export interface DataCapture {
    CaptureDateTime: boolean;
    DateTimeLabel: string;
    CaptureComment: boolean;
    CommentLabel: string;
}
