/**
 * Component for the Sales > Tasks page. `/`
 */

import { Button, Checkbox, Col, Modal, Row, Select, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import {
    difference,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
    sum,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from '../../components/common/ActionBar';
import FontAwesome from '../../components/common/FontAwesome';
import VirtualizedList from '../../components/common/VirtualizedList';
import TaskEscalateCollectionsPanel from '../../components/tasks/TaskEscalateCollectionsPanel';
import TasksItemComponent from '../../components/tasks/TaskItemComponent';
import TaskResolveCustomerAssistanceTicketPanelContent from '../../components/tasks/TaskResolveCustomerAssistanceTicketPanelContent';
import {
    confirmModalCancelText,
    confirmModalOkText,
    TASK_ID_URL_QUERY,
} from '../../config/config';
import {
    PAGE_NAMES_FOR_VIEW,
    TASKS_PAGE,
} from '../../config/tableAndPageConstants';
import {
    taskHistoryBatchTypes,
    tasksReadyStateOptions,
    tasksSortByOptions,
    tasksTypeOptions,
} from '../../constants/tasksSortAndFilters';
import { ApplicationState } from '../../store';
import { Company } from '../../store/companies/types';
import {
    checkTasksReadyStateAction,
    clearTaskDataAction,
    getTasksRequestAction,
    notifyCustomersRequestAction,
    setSelectedTaskIdRequestAction,
    updateTasksFiltersAction,
    updateTasksSortByAndStateAction,
    updateTasksTableFilterAction,
} from '../../store/tasks/actions';
import { initialState } from '../../store/tasks/reducer';
import { getSelectedTaskId } from '../../store/tasks/sagas';
import { GetTasksRequestPayload, TasksState } from '../../store/tasks/types';
import {
    checkIfTaskReady,
    computeTableScroll,
    emptyPredefinedFilterOnAppliedFilters,
    getUsedSortByOptionsIfCloud,
    handlePageViewSelection,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';

const TaskItemDetailsDrawerComponent = lazy(
    () => import('../../components/tasks/TaskItemDetailsDrawerComponent')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

interface IProps {
    readonly isUsingCloudImportType: boolean;
}
let resetTableScroll = false;
let isRefetching = false;
let fetchTasksRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = TASKS_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.TASKS_PAGE;
let initialTableFilter: undefined | string = undefined;
let fetchOnShowAllTasks = false;
const TasksManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
}: IProps) => {
    const dispatch = useDispatch();

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const selectedId = useSelector(getSelectedTaskId);

    const [taskDetailsDrawer, setTaskDetailsDrawer] = useState<{
        visible: boolean;
        drawerType: string;
    }>({
        visible: false,
        drawerType: taskHistoryBatchTypes.Communication,
    });

    const tasksState: TasksState = useSelector(
        (state: ApplicationState) => state.tasks
    );

    const [tasksTableFilter, setTasksTableFilter] = useState<
        string | undefined
    >(
        isEqual(tasksState.filters, initialState.filters)
            ? initialState.tableFilter
            : tasksState.tableFilter
    );

    const [actionBarItemsState, setActionBarItemsState] = useState<{
        actionButtonDisabled: boolean;
        actionButtonLoading: boolean;
        actionButtonSelectedType: undefined | string;
        actionButtonSelectedTicketTaskId: undefined | string;
    }>({
        actionButtonDisabled: true,
        actionButtonLoading: false,
        actionButtonSelectedType: undefined,
        actionButtonSelectedTicketTaskId: undefined,
    });

    const userCompanies: Company[] = useSelector(
        (state: ApplicationState) => state.companies.userCompanies
    );

    const [tableFilters, setTableFilters] = useState<any>(tasksState.filters);

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
        selectedUnreadyKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
        selectedUnreadyKeys: [],
    });

    const [isTaskFromLink, setIsTaskFromLink] = useState<boolean>(true);

    let isActionAllowed = true;

    let isActionButtonLoading = isActionAllowed
        ? actionBarItemsState.actionButtonLoading
        : false;

    /**
     * Function that checks if there are filters applied and saved in redux.
     * Returns a boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(tasksState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        actionDrawer: boolean;
        allSelected: boolean;
        certifyNotPaid: boolean;
        downloadToExcel: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        actionDrawer: false,
        allSelected: false,
        certifyNotPaid: false,
        downloadToExcel: false,
    });

    const [tableSortStatus, setTableSortStatus] = useState<{
        sortBy: string;
        sortAscending: boolean;
        taskStatus: string;
    }>({
        sortBy: tasksState.sortBy,
        sortAscending: tasksState.sortAscending,
        taskStatus: tasksState.taskStatus,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(tasksState, 'pageData.currentPage', 0)
    );

    const existingTaskId = localStorage.getItem(TASK_ID_URL_QUERY);

    const checkIdFromQueryString = () => {
        const isFromLink = existingTaskId ? true : false;
        if (isFromLink !== isTaskFromLink) {
            setIsTaskFromLink(isFromLink);
        }

        if (fetchOnShowAllTasks) {
            callFetchTasks();
        }
    };

    useEffect(checkIdFromQueryString, [existingTaskId]);
    /**
     * Function executed when isTaskFromLink value changes.
     * Used for updating the selectedRowKeys.
     */
    const checkIfIsTaskFromLink = () => {
        if (isTaskFromLink) {
            updateTableRowSelection({
                selectedRowKeys: [existingTaskId],
            });
        }
    };

    useEffect(checkIfIsTaskFromLink, [isTaskFromLink]);

    /**
     * Function for checking the action state of button based on the response of get tasks query
     */
    const checkActionStateForTaskFromLink = () => {
        if (isTaskFromLink && !tasksState.loading) {
            const taskType = get(tasksState.data, '0.Type');
            if (taskType === taskHistoryBatchTypes.Ticket) {
                const taskState = get(tasksState.data, '0.State');
                const taskActionDate = get(tasksState.data, '0.ActionDate');

                const isTaskReady = checkIfTaskReady(taskState, taskActionDate);
                updateActionBarItemsState({
                    actionButtonDisabled: !isTaskReady,
                    actionButtonSelectedType: taskType,
                });
                updateTaskDetailsDrawerState({
                    drawerType: taskType,
                });
            }
        }
    };

    useEffect(checkActionStateForTaskFromLink, [
        isTaskFromLink,
        tasksState.loading,
        tasksState.data,
    ]);

    /**
     * Function to get used sort options
     */
    const getTasksSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(
            tasksSortByOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        // if (isEmpty(userCompanies)) return;
        const usedSortByOptions = getTasksSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        if (!includes(usedSortValues, tasksState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortStatus,
                    sortBy: initialState.sortBy,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updateTasksSortByAndStateAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        userCompanies,
        tasksState.sortBy,
    ]);

    /**
     * Function for preparing the filters to be used as payload for API.
     */
    const readyAllFiltersSelected = () => {
        return {
            ...tasksState.filters,
            TaskIds: existingTaskId ? [existingTaskId] : undefined,
        };
    };
    const lockedDeliveryMethod = false;

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const { allExcept } = getSelectedTasksValues();

        const payload: GetTasksRequestPayload = {
            filters: readyAllFiltersSelected(),
            sortBy: tableSortStatus.sortBy,
            sortAscending: tableSortStatus.sortAscending,
            pageSize,
            currentPage: currentPage,
            taskTableFilter: tasksTableFilter,
            excludeTasks: allExcept,
        };

        return payload;
    };

    /**
     * Function that calls an action which triggers a saga for fetching the Tasks data.
     * @param currentPage - current page to fetch data from
     * @param pageSize - number of items in page
     */
    const fetchTasks = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getTasksRequestAction(payload));
    };

    /**
     * Function called in preparation for fetching the tasks data.
     * Manages the page to be called.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            // isEmpty(userCompanies) ||
            tasksState.loading
        )
            return;

        if (!tasksState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchTasks(nextPage);
    };

    /**
     * Listener function for calling the fetch tasks function.
     * Can be triggered upon changes on selected user company, filter values, sort values, and tasks state filter update.
     */
    const callFetchTasks = () => {
        // if (isEmpty(userCompanies)) return;

        // if (companyIdCognito === selectedCompanyId) {
        //     if (lastSelectedCompanyId !== selectedCompanyId) {
        //         lastSelectedCompanyId = selectedCompanyId;
        //         resetAllSelectedRowKeys();
        //         checkAllTableFiltersOnCompanySwitch();
        //         resetTableScrollAndPageData();
        //     }
        fetchTasks(0);
        // }
    };

    useEffect(callFetchTasks, [
        tasksState.sortBy,
        tasksState.sortAscending,
        tasksState.filters,
        tasksState.taskStatus,
        userCompanies,
    ]);

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = tasksState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchTasksRequired) {
                fetchTasks(0);
                fetchTasksRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [tasksState.tableFilter]);

    // on Unmount
    useEffect(() => {
        return () => {
            skipListenToPreconfiguredFilter = true;
            fetchOnShowAllTasks = false;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Listener function responsible for showing the state of checkboxes (checked/unchecked) upon data update.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(tasksState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [tasksState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function called when the filter value on upper left dropdown (next to refresh button) has been updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeTasksTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== tasksState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setTasksTableFilter(filter);
        resetTableScrollAndPageData();
        if (
            refetch &&
            !isUndefined(tasksState.tableFilter) &&
            filter === tasksState.tableFilter
        ) {
            handleTasksFilterRefresh();
        } else {
            dispatch(updateTasksTableFilterAction(filter));
        }
    };

    /**
     * Function called when the refresh button is clicked.
     */
    const handleTasksFilterRefresh = () => {
        fetchTasksRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchTasks(0);
    };

    /**
     * Function for populating the dropdown render of the table filter (upper-left).
     * @param menu - menu items component
     */
    const populateTasksFilterSelectDropdownRender = (menu: any) => (
        <div>{menu}</div>
    );

    /**
     * Function that checks if `Select all` is in effect.
     * Returns a boolean.
     */
    const checkIfSelectAll = () => {
        const allDataLoaded = tasksState.pageData.hasNextPage === false;

        return (
            !isEmpty(tasksState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
                (allDataLoaded &&
                    tasksState.data.length ===
                        tableRowSelection.selectedRowKeys.length))
        );
    };

    /**
     * Function for populating the `Select/Deselect all` label.
     */
    const populateSelectDeselectAllLabel = () => {
        let selectDeselectLabel = '';
        if (checkIfSelectAll()) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return `${selectDeselectLabel} all`;
    };

    /**
     * Function called when the sort order button link is clicked (inside the ellipsis popover).
     * @param sortAscending - boolean indicator if sort order is ascending
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateTasksSortByAndStateAction({
                ...tableSortStatus,
                sortAscending,
            })
        );
    };

    const downloadDisabled = tasksState.loading || isEmpty(tasksState.data);

    /**
     * Function for populating the upper section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                Filter
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortStatus((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            tasksState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                Change sort order
            </Button>
        </div>
    );

    /**
     * Function for populating the lower section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverContent = () => (
        <div className="pop-action-content">
            <div className="mb-10">
                <span>Sort by</span>
            </div>
            <div>
                <Select
                    onChange={(sortBySelected: string) =>
                        updateTableSortStateObject({
                            sortBy: sortBySelected,
                        })
                    }
                    value={tableSortStatus.sortBy}
                >
                    {map(
                        getTasksSortByOptions(),
                        ({
                            label,
                            value,
                        }: {
                            label: string;
                            value: string;
                        }) => (
                            <Option key={value} value={value}>
                                {label}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="ta-right">
                <Button
                    type="primary"
                    disabled={
                        tasksState.loading ||
                        tableSortStatus.sortBy === tasksState.sortBy
                    }
                    onClick={applySortedByAndState}
                >
                    Apply
                </Button>
            </div>
        </div>
    );

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (tasksState.tableFilter !== initialTableFilter) {
            setTasksTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeTasksTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called when applying the filters.
     * Set the filters in state and redux.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetTasksRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    tasksTableFilter,
                    fromFilterBar,
                    () => {
                        changeTasksTableFilter('');
                    }
                );
            }
        }

        const appliedFilters = filters || initialState.filters;

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateTasksFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys();
    };

    const applySortedByAndState = async () => {
        await resetTableScrollAndPageData();
        await dispatch(updateTasksSortByAndStateAction(tableSortStatus));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param showConditionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function for updating the `actionBarItemsState` state.
     * @param itemStateObject - must conform to `actionBarItemsState`
     */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState({
            ...actionBarItemsState,
            ...itemStateObject,
        });
    };

    /**
     * Function for updating the `taskDetailsDrawer` state.
     * @param taskDetailsDrawerStateObject - must conform to `taskDetailsDrawer` state
     */
    const updateTaskDetailsDrawerState = (taskDetailsDrawerStateObject: {}) => {
        setTaskDetailsDrawer({
            ...taskDetailsDrawer,
            ...taskDetailsDrawerStateObject,
        });
    };

    /**
     * Function called to close the tasks details drawer.
     * Clears the data saved during drawer close.
     */
    const closeTaskDetailsDrawer = () => {
        updateTaskDetailsDrawerState({
            visible: false,
        });
        dispatch(clearTaskDataAction());
    };

    /**
     * Function to update the `tableSortState` state.
     * @param tableSortStateObject - must conform to `tableSortState`
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortStatus({
            ...tableSortStatus,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when clicking on checkbox.
     * @param record - record for the row where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when a row item is clicked.
     * @param record - data for the clicked row item
     */
    const onRowClick = (record: DynamicObject) => {
        dispatch(
            setSelectedTaskIdRequestAction(get(record, 'Id'), () => {
                const drawerType = get(record, 'Type');
                updateTaskDetailsDrawerState({
                    visible: true,
                    drawerType,
                });
            })
        );
    };

    /**
     * Function for populating the table loading text.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } tasks`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Common function for getting the filter values, and keys to be used in payload when calling the API.
     * @param selectedRowKeys
     * @param unselectedRowKeys
     */
    const getSelectedTasksValues = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        let allExcept = false;
        let keysToUse = selectedRowKeys || [
            ...tableRowSelection.selectedRowKeys,
        ];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = unselectedRowKeys || [
                ...tableRowSelection.unselectedRowKeys,
            ];
        }
        const filters = {
            ...tasksState.filters,
        };
        const filterObject = {
            ...filters,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    /**
     * Function that checks the ready state of the selected tasks.
     * To be used to enable/disable the `Action` button.
     * @param selectedRowKeys - array of selected keyss
     * @param unselectedRowKeys - array of unselected keys
     */
    const checkReadyStateForSelectedTasks = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        const { allExcept, keysToUse, filterObject } = getSelectedTasksValues(
            selectedRowKeys,
            unselectedRowKeys
        );

        const payload: GetTasksRequestPayload = {
            filters: { ...filterObject },
            sortBy: tableSortStatus.sortBy,
            sortAscending: tableSortStatus.sortAscending,
            pageSize: tablePageSize,
            taskIds: keysToUse,
            excludeTasks: allExcept,
            currentPage: tableCurrentPage,
            taskTableFilter: tasksTableFilter,
            callback: (tasksActionState: any) => {
                updateActionBarItemsState({
                    actionButtonDisabled: !tasksActionState.ActionEnabled,
                    actionButtonSelectedType: tasksActionState.ActionType,
                    actionButtonLoading: false,
                });
            },
        };

        dispatch(checkTasksReadyStateAction(payload));
    };

    /**
     * Function called when the row is selected (left side checkbox is ticked).
     * @param record - data for the selected row
     * @param selected - boolean indicator if the row is selected/unselected
     * @param selectedRows - selected row keys
     * @param nativeEvent - event fired when the row is selected
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];

        const selectedUnreadyKeys = [...tableRowSelection.selectedUnreadyKeys];

        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
            if (!record.isReady) {
                selectedUnreadyKeys.push(record.key);
            }
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];

            if (!record.isReady) {
                remove(
                    selectedUnreadyKeys,
                    (unReadyKey) => unReadyKey === record.key
                );
            }
        }

        const { allExcept } = getSelectedTasksValues();

        let isActionNotAllowedForSelected =
            !isEmpty(selectedUnreadyKeys) || isEmpty(selectedRowKeys);

        if (allExcept) {
            isActionNotAllowedForSelected =
                actionBarItemsState.actionButtonDisabled;
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
            selectedUnreadyKeys,
        });

        if (isActionAllowed) {
            if (showConditions.allSelected) {
                updateActionBarItemsState({
                    actionButtonLoading: true,
                });

                checkReadyStateForSelectedTasks(
                    selectedRowKeys,
                    unselectedRowKeys
                );
            } else {
                const selectedTypes: any[] = [];
                forEach(tasksState.data, (taskData) => {
                    if (includes(selectedRowKeys, taskData.Id)) {
                        const type = taskData.Type;
                        if (!includes(selectedTypes, type))
                            selectedTypes.push(type);
                    }
                });

                const selectedTypesLength = selectedTypes.length;
                const currentlySelectedType =
                    selectedTypesLength === 1 ? selectedTypes[0] : undefined;

                if (selectedTypesLength > 1) {
                    isActionNotAllowedForSelected = true;
                } else if (
                    currentlySelectedType === taskHistoryBatchTypes.Ticket &&
                    selectedRowKeys.length > 1
                ) {
                    isActionNotAllowedForSelected = true;
                } else if (
                    selectedRowKeys.length === 1 &&
                    currentlySelectedType !== taskHistoryBatchTypes.Ticket
                ) {
                    // Remove this checking once other items can be actioned
                    isActionNotAllowedForSelected = true;
                }

                updateActionBarItemsState({
                    actionButtonDisabled: isActionNotAllowedForSelected,
                    actionButtonSelectedType: currentlySelectedType,
                    actionButtonSelectedTicketTaskId:
                        currentlySelectedType === taskHistoryBatchTypes.Ticket
                            ? selectedRowKeys[0]
                            : undefined,
                });
            }
        }
    };

    /**
     * Function to control the visibility of popovers.
     * @param name - name of popover to change the visibility
     * @param condition - boolean indicator to apply the condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (name === 'actionDrawer') {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for resetting the table scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all the selected/unselected row keys.
     */
    const resetAllSelectedRowKeys = () => {
        // reset Selected Row Keys after change role success
        if (isUndefined(initialTableFilter) || isTaskFromLink) return;

        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });

        updateShowConditionsObject({
            allSelected: false,
        });

        updateActionBarItemsState({
            actionButtonDisabled: !isTaskFromLink,
        });
    };

    /**
     * Function for closing the filter bar section.
     * Resets the applied filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function for resetting the action drawer values (state).
     * @param refreshList
     */
    const resetActionDrawerValues = (refreshList?: boolean) => {
        updateShowConditionsObject({
            actionDrawer: false,
            certifyNotPaid: false,
        });
        if (refreshList) {
            handleShowAllTasks();
            refetchListAndResetScroll();
            updateTableRowSelection({
                selectedRowKeys: [],
                unselectedRowKeys: [],
                selectedUnreadyKeys: [],
            });
        }
    };

    /**
     * Function to populate the drawer title and message (basically the content) based on contact type.
     */
    const getActionDrawerTitleMessageNoteBasedOnContactType = () => {
        const contactTypeSum = sum(get(tasksState, 'filters.ContactType'));
        let title = 'Send email, SMS, and postal letter';
        let message = `Each customer is going to be notified using the best combination of email, sms, and postal letter.`;
        let note = `** You are notified when the postal letters are ready for
                    printing.`;
        if (lockedDeliveryMethod) {
            message =
                'Each customer is going to be notified using the best contact methods available for the task.';
        } else {
            if (contactTypeSum === 3) {
                title = 'Send email and SMS only';
                message =
                    'Use the best combination of email and sms for each customer.';
                note = '';
            } else if (contactTypeSum === 5) {
                title = 'Send email and postal letter only';
                message =
                    'Each customer is going to be notified using the best combination of email and postal letter, with a preference to email.';
            } else if (contactTypeSum === 6) {
                title = 'Send SMS and postal letter only';
                message =
                    'Each customer is going to be notified using the best combination of SMS and postal letter, with a preference to SMS.';
            } else if (contactTypeSum === 1) {
                title = 'Send email only';
                message = 'Only send emails to each customer.';
                note = '';
            } else if (contactTypeSum === 2) {
                title = 'Send SMS only';
                message = 'Only send SMS to each customer.';
                note = '';
            } else if (contactTypeSum === 4) {
                title = 'Send postal letters only';
                message = 'Only produce letters for each customer.';
                note =
                    '** You are required to print and send these letters. You are notified when the postal letters are ready for printing.';
            }
        }

        return {
            title,
            message,
            note,
        };
    };

    /**
     * Function for populating the action drawer title and content based on the item/s selected.
     */
    const getActionPopoverTitleAndContent = () => {
        if (!showConditions.actionDrawer) {
            return {
                actionTitle: '',
                actionContent: '',
            };
        } else {
            if (
                actionBarItemsState.actionButtonSelectedType ===
                taskHistoryBatchTypes.Ticket
            ) {
                return {
                    actionTitle: 'Action customer assistance ticket',
                    actionContent: (
                        <TaskResolveCustomerAssistanceTicketPanelContent
                            visible={showConditions.actionDrawer}
                            closePanel={resetActionDrawerValues}
                            selectedTicketTaskId={
                                actionBarItemsState.actionButtonSelectedTicketTaskId ||
                                get(tableRowSelection.selectedRowKeys, 0)
                            }
                        />
                    ),
                };
            } else if (
                actionBarItemsState.actionButtonSelectedType ===
                taskHistoryBatchTypes.Collection
            ) {
                return {
                    actionTitle: 'Escalate to collections',
                    actionContent: (
                        <TaskEscalateCollectionsPanel
                            visible={showConditions.actionDrawer}
                            closePanel={resetActionDrawerValues}
                            getSelectedTasksValues={getSelectedTasksValues}
                        />
                    ),
                };
            } else {
                const { title, message, note } =
                    getActionDrawerTitleMessageNoteBasedOnContactType();

                return {
                    actionTitle: title,
                    actionContent: populateActionPopoverContent(message, note),
                };
            }
        }
    };

    /**
     * Function called when the checkbox inside `Action` popover for automation tasks has been ticked.
     * @param e
     */
    const onCertifyNotPaidChange = (e: CheckboxChangeEvent) => {
        updateShowConditionsObject({
            certifyNotPaid: e.target.checked,
        });
    };

    /**
     * Function called when `Notify customers` button has been clicked.
     * Shows a confirmation modal if you want to proceed with the action or not.
     */
    const notifyCustomers = () => {
        const { allExcept, keysToUse, filterObject } = getSelectedTasksValues();

        confirm({
            className: 'modal-swapped-buttons',
            title: 'Confirm',
            content: `Are you sure you want to send customers notification ${
                tableRowSelection.selectedRowKeys.length > 1
                    ? 'these tasks'
                    : 'this task'
            }?`,
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
            onOk() {
                dispatch(
                    notifyCustomersRequestAction({
                        filter: filterObject,
                        taskIds: keysToUse,
                        excludeTasks: allExcept,
                        callback: notifyCustomersResponseModal,
                    })
                );
            },
        });
    };

    /**
     * Function for populating the response modal after Notify customers action API call has finished.
     * @param param0
     */
    const notifyCustomersResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages?: string[];
    }) => {
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: `Customers notification sent successfully!`,
                onOk: () => {
                    // resetTableScrollAndPageData();
                    handleShowAllTasks();
                    resetActionDrawerValues(true);
                    updateActionBarItemsState({
                        actionButtonDisabled: true,
                        actionButtonLoading: false,
                    });
                    updateShowConditionsObject({
                        allSelected: false,
                        actionDrawer: false,
                        certifyNotPaid: false,
                    });
                },
            });
        } else {
            let errorMessageContent:
                | string
                | JSX.Element[] = `Failed to notify customers!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                onOk: () => {
                    updateShowConditionsObject({
                        certifyNotPaid: false,
                    });
                },
            });
        }
    };

    /**
     * Function for populating the action popover content for automation tasks.
     * @param message
     * @param note
     */
    const populateActionPopoverContent = (message: string, note: string) => (
        <div className="pa-5">
            <div className="mb-10">{message}</div>
            <div className="mb-10">{note}</div>
            <div className="mb-10 mt-10">
                For information on how to change what communication is used,{' '}
                <a
                    href="https://www.manula.com/manuals/iodm-limited/iodm/connect/en/topic/actioning-tasks"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    click here
                </a>
                .
            </div>
            <div>
                <div className="mb-10">
                    To satisfy compliance needs, you must click on the tick box
                </div>
                <div className="mb-10">
                    <Checkbox
                        onChange={onCertifyNotPaidChange}
                        checked={showConditions.certifyNotPaid}
                    >
                        <span className="grey">
                            I certify that these customer accounts have not been
                            paid, are not disputed, and have the correct contact
                            details.
                        </span>
                    </Checkbox>
                </div>
                <div className="mt-10 ta-right">
                    <Button
                        className={`${
                            showConditions.certifyNotPaid ? 'buttonBlue' : ''
                        } mr-10`}
                        onClick={notifyCustomers}
                        disabled={!showConditions.certifyNotPaid}
                        type="primary"
                    >
                        Notify Customers
                    </Button>
                    <Button
                        className="buttonGrey"
                        onClick={() => {
                            resetActionDrawerValues();
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );

    /**
     * Function to populate the modal title when `Notify customers` action has proceeded.
     */
    const populateNotifyCustomerRequestModalTitle = () =>
        'Sending customer notifications';

    /**
     * Function to populate the modal body when `Notify customers` action has proceeded.
     */
    const populateNotifyCustomerRequestModalDisplayMessage = () =>
        `Please wait while sending the notifications. . .`;

    /**
     * Function called when `Select/Deselect all` button has been clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length < tasksState.data.length;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(tasksState.data, selectedKeysName);
            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
            updateActionBarItemsState({
                actionButtonLoading: true,
            });
            if (isActionAllowed) checkReadyStateForSelectedTasks([], []);
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
                selectedUnreadyKeys: [],
            });

            updateActionBarItemsState({
                actionButtonDisabled: true,
                actionButtonLoading: false,
            });
        }
    };

    /**
     * Function to populate the `Action` button view.
     */
    const populateActionProtectedPopoverButtonContent = () => (
        <>
            <FontAwesome icon={['fas', 'forward']} />
            <span>Action</span>
        </>
    );

    const generateFilterItems = () => {
        const filterItems = [
            // {
            //     filterName: 'Priority',
            //     filterStateName: 'Priority',
            //     filterElement: 'checkbox-group',
            //     filterType: 'array',
            //     filterOptions: tasksPriorityTypeOptions,
            // },
            {
                filterName: 'Type',
                filterStateName: 'Type',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: tasksTypeOptions,
            },
            {
                filterName: 'Ready state',
                filterStateName: 'ActionDate',
                filterPrefixString: 'Ready',
                filterElement: 'select',
                filterType: 'text',
                filterOptions: tasksReadyStateOptions,
                filterOptionsCustomTypes: {
                    'Custom date range': 'date-range',
                    'Custom days range': 'all-days-range-from-to',
                },
            },
            {
                filterName: 'Company name',
                filterStateName: 'CompanyId',
                filterElement: 'select-search',
                filterQuery: 'GET_COMPANIES_FOR_EXTERNAL_USER_AUTOCOMPLETE',
                filterResponse: 'GetCompaniesForExternalUser.Companies',
                filterSearchName: ['Name'],
            },
        ];

        return filterItems;
    };

    /**
     * Function called when show all tasks button is called.
     */
    const handleShowAllTasks = () => {
        fetchOnShowAllTasks = true;
        if (localStorage.getItem(TASK_ID_URL_QUERY))
            localStorage.removeItem(TASK_ID_URL_QUERY);
        setIsTaskFromLink(false);
    };

    /**
     * Common function for getting action bar action items.
     */
    const getActionBarActionItems = () => {
        const actionItemList = {
            taskTableFilter: {
                actionKey: 'tasks-filter',
                actionType: 'select-with-button',
                selectValue: tasksTableFilter,
                selectDropdownRender: populateTasksFilterSelectDropdownRender,
                onSelectChange: changeTasksTableFilter,
                buttonContent: (
                    <>
                        <FontAwesome icon={['fa', 'sync']} className="mr-8" />
                        <span>Refresh</span>
                    </>
                ),
                buttonDisabled: tasksState.loading,
                onButtonClick: handleTasksFilterRefresh,
            },
            taskSelectAll: {
                actionKey: 'task-select-all',
                actionType: 'protected-button',
                buttonDisabled: downloadDisabled,
                onButtonClick: selectAllRows,
                buttonLoading: isActionButtonLoading,
                buttonContent: (
                    <>
                        <FontAwesome icon={['fas', 'check-double']} />
                        <span>{populateSelectDeselectAllLabel()}</span>
                    </>
                ),
            },
            taskActionBtn: {
                actionKey: 'task-action',
                actionType: 'protected-drawer-button',
                popoverVisible: showConditions.actionDrawer,
                drawerCloseable: false,
                popoverOnVisibleChange: popoverOnVisibleChange(
                    'actionDrawer',
                    !isEmpty(tableRowSelection.selectedRowKeys)
                ),
                popoverTitle: actionTitle,
                popoverContent: actionContent,
                buttonLoading: isActionButtonLoading,
                buttonDisabled:
                    !isActionAllowed ||
                    actionBarItemsState.actionButtonDisabled,
                buttonContent: populateActionProtectedPopoverButtonContent(),
            },
            taskShowAllTasksBtn: {
                actionKey: 'task-show-all-tasks',
                actionType: 'protected-button',
                // buttonDisabled: downloadDisabled,
                onButtonClick: handleShowAllTasks,
                buttonContent: (
                    <>
                        <FontAwesome icon={['fas', 'list']} />
                        <span>Show all tasks</span>
                    </>
                ),
            },
        };

        const actionItems = isTaskFromLink
            ? [actionItemList.taskActionBtn, actionItemList.taskShowAllTasksBtn]
            : [
                  actionItemList.taskTableFilter,
                  //   actionItemList.taskSelectAll,
                  actionItemList.taskActionBtn,
              ];
        return actionItems;
    };

    const { actionTitle, actionContent } = getActionPopoverTitleAndContent();

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>Tasks</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    // pageName={pageName}
                                    loading={tasksState.loading}
                                    applyFilters={applyFilters}
                                    filters={generateFilterItems()}
                                    filterValues={tasksState.filters}
                                    colDivision={4}
                                    closeAllPopovers={taskDetailsDrawer.visible}
                                    closeFilterBar={closeFilterBar}
                                    appliedView={tasksTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                />
                            </Suspense>
                        </div>
                    )}
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={tasksState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={getActionBarActionItems()}
                        actionEllipsis={
                            !isTaskFromLink && {
                                popoverVisible: showConditions.filterEllipsis,
                                popoverOnVisibleChange:
                                    popoverOnVisibleChange('filterEllipsis'),
                                popoverTitle:
                                    populateFilterEllipsisPopoverTitle(),
                                popoverContent:
                                    populateFilterEllipsisPopoverContent(),
                                buttonContent: (
                                    <FontAwesome icon={['fas', 'ellipsis-h']} />
                                ),
                            }
                        }
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={tasksState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                TASKS_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={
                                isTaskFromLink ? undefined : onCheckboxClick
                            }
                            loading={tasksState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(tasksState.errorMessages)
                                    ? get(tasksState, 'errorMessages.0')
                                    : 'No tasks found'
                            }
                            hasNextPage={tasksState.pageData.hasNextPage}
                            itemComponent={TasksItemComponent}
                            itemHeight={TASKS_PAGE.rowHeight}
                            selectedId={selectedId}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <TaskItemDetailsDrawerComponent
                            visible={taskDetailsDrawer.visible}
                            closeDrawer={closeTaskDetailsDrawer}
                            drawerType={taskDetailsDrawer.drawerType}
                        />
                    </Suspense>
                    {tasksState.notifyCustomersLoading && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={populateNotifyCustomerRequestModalTitle()}
                                modalVisible={tasksState.notifyCustomersLoading}
                                displayMessage={populateNotifyCustomerRequestModalDisplayMessage()}
                            />
                        </Suspense>
                    )}
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={populateDownloadToExcelModalTitle()}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={populateDownloadToExcelModalDisplayMessage()}
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default TasksManagementPage;
